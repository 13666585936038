.burger {
  position: absolute;
  animation: fadeIn 1s;
  width: 40px;
  bottom: 5vh;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transition: all 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
  transition: all 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
  .ingredient {
    position: relative;
    width: inherit;
    &.top-bun {
      bottom: -9px;
      -webkit-transition: all 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
      transition: all 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
    }
    &.medium-done-patty {
    }
    &.bottom-bun {
      top: -11px;
      -webkit-transition: all 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
      transition: all 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
    }
  }
  &.open {
    // width: 70px;
    z-index: 2;
    .ingredient {
      &.top-bun {
        bottom: 5px;
      }
      &.medium-done-patty {
      }
      &.bottom-bun {
        top: 4px;
      }
    }
  }
}

.nav-list {
  display: none;
  opacity: 0;
  .nav-item {
    opacity: 0
  }
  &.open {
    display: block;
    position: absolute;
    width: 100vw;
    bottom: 0vh;
    opacity: 1;
    -webkit-transition: all 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
    transition: all 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
    .nav-item {
      font-size: 3vw;
      opacity: 1;
      font-weight: 600;
      color: white;
      position: relative;
      display: inline-block;
      padding: 0vw 5vw 5.5vh 0vw;
      -webkit-transition: all 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
      transition: all 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
      &:hover{
        color: black;
        text-decoration: line-through white;
        cursor: cell;
        -webkit-transition: all 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
        transition: all 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
      }
      &.left{
        text-align: left;
        left: -10vw;
      }
      &.right{
        text-align: right;
        left: 15vw;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
.burger{
  bottom: 6vh !important;
}
  .nav-list.open{
    bottom: 3vh !important;
  }
}