.terminal-holder {
  position: absolute;

  width: inherit;
  height: inherit;
  z-index: 11;
  -webkit-transition: all 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
  transition: all 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
  &.closed {
    // top: -100vh;
    top: 0px;
    visibility: hidden;
  }
  &.open {
    top: 0px;
    visibility: visible;
    animation: shake 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
