.showcase-holder{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80vw;
    height: 80vh;
    .showcase-section{
        width: 100%;
    height: 100%;
    display: block;
    position: relative;
        .writeup{
            display: inline-block;
            position: relative;
            width: 50%;
            text-align: left;
            .showcase-title{
                font-size: 2.5em;
                margin: 0;
            }
            .showcase-text{
                font-size: 1.25em;
                margin: 10px 0px;
            }
        }
        .image-section{
            display: inline-block;
            width: 50%;
            position: relative;
            height: 100vh;
            overflow-y: scroll;
            .hemi-left {
                position: absolute;
                top: 220vh;
            }
            .hemi-right{
                position: absolute;
                top: 200vh;
                left:10vw;
            }
            img{
                position: relative;
                width: 10vw;
            }
        }
    }
}