.links {
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 20px;
  .link-item {
    margin-right: 20px;
    display: block;
    &#linkedin {
      width: 50px;
    }
    &#Instagram {
      width: 50px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .links {
    .link-item {
      &#Instagram, &#linkedin {
        width: 30px;
      }
    }
  }
}
