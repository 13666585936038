.textHolder {
  position: absolute;
  top: -50px;
  left: 10px;
  height: 90vh;
  width: 90vw;
  font-size: 2em;
  color: white;
  font-weight: bolder;
  opacity: 0;
  overflow: hidden;
  line-height: 1.3;
  text-align: justify;
  pointer-events: none;  
  &.open {
    opacity: 0.1;
  }
}
